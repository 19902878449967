import Navlink from './Navlink';

const Navbar = ({ activeTab }) => (
  <div className="px-[50px]">
    <div className="flex py-[30px] gap-[15px] items-center border-b border-gray-300">
      <Navlink to="sunglasses" activeTab={activeTab}/>
      <Navlink to="eyeglasses" activeTab={activeTab}/>
    </div>
  </div>
);

export default Navbar;
