import { useMemo, useState, useEffect } from "react";
import Product from "../Product";
import ProductDetailsModal from "../ProductDetailsModal";

const ProductGroup = ({ tab, group }) => {
  const [frameObject, setFrameObject] = useState({});
  const [filtredProduct, setFiltredProduct] = useState([]);

  const lessPrice = useMemo(() => {
    const prices = group.map((product) => +product.price.amount);
    return Math.min(...prices);
  }, [group]);

  const comparePrice = useMemo(() => {
    const priceV2 = group.map((e) => +e.priceV2.amount);
    return Math.min(...priceV2);
  }, [group]);
  const [productModal, setProductModal] = useState(null);

  const openProductModal = (product, sku) => {
    document.body.style.overflowY = "hidden";
    const frameGroup = frameObject[sku.slice(0, 6)];
    setProductModal({ product, frameGroup });
  };

  const closeProductModal = () => {
    document.body.style.overflowY = "visible";
    setProductModal(null);
  };

  const filterByTitle = () => {
    const newArr = [];
    group.filter((e) => {
      if (
        e.title.includes(tab === "sunglasses" ? "Smoke" : "Clear") &&
        e.title.includes("Standard") &&
        e.title.includes("Single Vision")
      ) {
        newArr.push(e);
        setFiltredProduct(newArr);
      }
    });
  };

  const filterByFrame = () => {
    const filteredItems = {};

    for (const product of group) {
      const sku = product.sku;
      const rx = sku.substring(0, 6);

      if (filteredItems[rx]) {
        filteredItems[rx].push(product);
      } else {
        filteredItems[rx] = [product];
      }
    }
    setFrameObject(filteredItems);
  };

  useEffect(() => {
    filterByFrame();
  }, [group, tab]);

  useEffect(() => {
    filterByTitle();
  }, [group, tab]);

  return (
    <div className="w-full overflow-x-auto flex flex-col h-full">
      {filtredProduct?.length > 0 && (
        <div className="absolute">
          <h3 className="text-[#232B38] font-serif text-[24px] font-extrabold">
            {filtredProduct[0].mainTitle}
          </h3>

          <p className="text-[#232B38] mb-[20px] text-[14px]">
            From{" "}
            {lessPrice < comparePrice ? (
              <span className="text-[16px] text-red-500">${comparePrice}</span>
            ) : null}{" "}
            <span
              className={`text-[16px] ${
                lessPrice < comparePrice ? "line-through" : null
              }`}
            >
              ${lessPrice}
            </span>
          </p>
        </div>
      )}

      <div className="flex w-fit flex-row gap-[20px] mt-10">
        {filtredProduct.map((product) => (
          <div
            key={product.id}
            onClick={() => {
              openProductModal(product, product.sku);
            }}
          >
            <Product product={product} />
          </div>
        ))}
      </div>
      {productModal && (
        <ProductDetailsModal
          tab={tab}
          productsInfo={productModal}
          closeModal={closeProductModal}
        />
      )}
    </div>
  );
};

export default ProductGroup;
