import Products from '../../components/Products';
import Header from '../../components/shared/Header';

const Glasses = ({ tab }) => {

  return (
    <>
      <Header activeTab={tab} />
      <Products tab={tab}/>
    </>
  )
}

export default Glasses;
