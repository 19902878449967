import { useEffect, useMemo, useState } from "react";
import client from "../../shopify/client";
import Loading from "../shared/Loading";
import ProductGroup from "./ProductGroup";

const Products = ({ tab }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productList, setProductList] = useState([]);

  const type = useMemo(
    () => ({
      sunglasses: "267706040389",
      eyeglasses: "267679531077",
    }),
    []
  );

  useEffect(() => {
    fetchData();
  }, [tab]);

  const fetchData = async () => {
    setLoading(true);
    const collectionId = `gid://shopify/Collection/${type[tab]}`;
    const { products } = await client.collection.fetchWithProducts(
      collectionId, {productsFirst: 100}
    );
    setProducts(products);
    setLoading(false);
  };

  const filterProduct = () => {
    const filteredItems = {};

    for (const product of products) {
      let title = product.descriptionHtml.match(/<strong>([\d\s\w]{2,})<\/strong>/);
      title &&= title[1];

      product.variants?.forEach((variant) => {
        const sku = variant.sku;
        const fourDigits = sku.substring(0, 4);
        const rxNumber = fourDigits;

        if (filteredItems[fourDigits]) {
          filteredItems[rxNumber].push({
            ...variant,
            mainTitle: title,
            frameTitle: product.title.slice(0, product.title.length - 5),
          });
        } else {
          filteredItems[rxNumber] = [
            {
              ...variant,
              mainTitle: title,
              frameTitle: product.title.slice(0, product.title.length - 5),
            },
          ];
        }
      });
    }
    const arrayOfItems = [];

    for (const itemIndex in filteredItems) {
      arrayOfItems.push(filteredItems[itemIndex]);
    }

    setProductList(arrayOfItems);
  };

  useEffect(() => {
    setProductList([]);
    if (products) filterProduct();
  }, [products, tab]);

  return (
    <div className="px-[50px] py-[40px] w-full overflow-auto bg-white">
      <div className="flex flex-row w-fit gap-[55px] bg-white"></div>
      {loading ? (
        <Loading />
      ) : (
        <div className="flex flex-col w-full gap-[55px] bg-white">
          {productList.map((el, index) => (
            <ProductGroup key={index} tab={tab} group={el} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Products;
